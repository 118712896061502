import { Component, OnInit } from '@angular/core';
import { FactoryEmployeeService } from 'app/_services/factory-employee.service';
import { FormBuilder, FormGroup, Validators, FormArray} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomvalidationService } from 'app/_services/customvalidation.service';
import { AuthenticationService } from 'app/auth/service';

@Component({
  selector: 'app-factory-employee-add',
  templateUrl: './factory-employee-add.component.html',
  styleUrls: ['./factory-employee-add.component.scss']
})
export class FactoryEmployeeAddComponent implements OnInit {

  public userId:any = 0;
  public loading = false;
  public error = '';
  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public passwordTextType2: boolean;
  public passwordTextType3: boolean;
  
  public product_selected_photo_one: any = '';
  public product_selected_photo_two: any = '';
  public product_selected_photo_three: any = '';
  public product_selected_photo_four: any = '';

  public product_selected_photo_oneAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_twoAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_threeAvatarImage: string = 'assets/images/upload_placeholder.png';
  public product_selected_photo_fourAvatarImage: string = 'assets/images/upload_placeholder.png';
  public readerImg: string = '';

constructor(private _authenticationService: AuthenticationService,  private factoryEmployeeService: FactoryEmployeeService, private _toastrService: ToastrService, private _router: Router, private route: ActivatedRoute, private _formBuilder: FormBuilder, private customValidator: CustomvalidationService) { }

  ngOnInit(): void {
    if (this.route.snapshot.params['id'] !== undefined) {
      this.userId = this.route.snapshot.params['id'];
    }
    this.isAddMode = !this.userId;
    if (!this.isAddMode) {
      this.edit(this.userId);
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^[0-9]{10}')]],
        email: ['', [Validators.email]],
        address: [null, [Validators.required]],
        product_photo_one: [''],
        product_photo_two: [''],
        product_photo_three: [''],
        product_photo_four: [''],
      });
    }
    else {
      this.newForm = this._formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.min(6000000000), Validators.max(9999999999), Validators.pattern('^[0-9]{10}')]],
        email: ['', [Validators.email]],
        address: [null, [Validators.required]],
        product_photo_one: [''],
        product_photo_two: [''],
        product_photo_three: [''],
        product_photo_four: [''],
      });
    }
  }

  get f() {
    return this.newForm.controls;
  }

  get documents(): FormArray {
    return this.newForm.get('documents') as FormArray;
  }

  togglePasswordTextType(me) {
    if (me == 2) {
      this.passwordTextType2 = !this.passwordTextType2;
    }
    else {
      this.passwordTextType3 = !this.passwordTextType3;
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.valid) {
      this.loading = true;
      const { name, phone, email, address} = this.newForm.controls;

      const formdata = new FormData();
      formdata.append('product_photo_one', this.product_selected_photo_one);
      formdata.append('product_photo_two', this.product_selected_photo_two);
      formdata.append('product_photo_three', this.product_selected_photo_three);
      formdata.append('product_photo_four', this.product_selected_photo_four);
      formdata.append('name', name.value);
      formdata.append('mobile', phone.value);
      formdata.append('email', email.value);
      formdata.append('address', address.value);
      formdata.append('id', this.userId);

      this.factoryEmployeeService.add(formdata, this.userId).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.userId = 0
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this._router.navigate(['/factory-employee']);
          }
          else {
            if (data.error.mobile) {
              this.error = data.error.mobile[0];
            }
            else {
              this.error = data.error;
            }
          }

        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }else {
      this._toastrService.error('Validation Error', 'Error!', { toastClass: 'toast ngx-toastr', closeButton: true });
    }
  }


  edit(id: any) {
    this.factoryEmployeeService.edit(id).subscribe(
      response => {
        if (response.status) {
          this.userId = response.employee.id;
          this.f.name.setValue(response.employee.name);
          this.f.phone.setValue(response.employee.mobile);
          this.f.email.setValue(response.employee.email);
          this.f.address.setValue(response.employee.address);
          this.f.status.setValue(response.employee.status);
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  
  uploadFile(event: any, type: any) {

    if (type == 1) {
      this.product_selected_photo_one = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_oneAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }

    

    } else if (type == 2) {
      this.product_selected_photo_two = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_twoAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }

   

    } else if (type == 3) {
      this.product_selected_photo_three = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_threeAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }


    } else {
      this.product_selected_photo_four = event.target.files[0];

      if (event.target.files && event.target.files[0]) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.product_selected_photo_fourAvatarImage = event.target.result;
        };
        reader.readAsDataURL(event.target.files[0]);
      }

     
    }
  }

}
