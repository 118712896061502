<div class="content-wrapper container-xxl p-0">
    <div class="content-body">

        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Products Combo</h4>
                        </div>


                        <div class="card-body pb-1">
                            <div class="overlay" *ngIf="progressMainTable">
                                <div class="spinner-grow" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                            <form name="form" autocomplete="off" [formGroup]="newForm">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div class="form-group">
                                            <label for="products_id" class="form-label">Select Products<span
                                                    class="text-danger">*</span></label>
                                            <ng-select formControlName="products_id" 
                                                aria-describedby="products_id" tabindex="1"
                                                [ngClass]="{ 'is-invalid': submitted && f.products_id.errors }">
                                                <ng-option disabled selected value="0">Select Products</ng-option>
                                                <ng-option *ngFor="let product of productsDropDown"
                                                    [value]="product.id">{{product.full_name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.products_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.products_id.errors.required">Products is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-12 col-md-4">
                                        <div class="form-group">
                                            <label class="form-label">Name<span class="text-danger">*</span></label>
                                            <input type="text" formControlName="combo_name" id="combo_name" placeholder="Combo Name" class="form-control" aria-describedby="combo_name" [ngClass]="{ 'is-invalid': submitted && f.combo_name.errors }">
                                            <div *ngIf="submitted && f.combo_name.errors" class="invalid-feedback">
                                                <div *ngIf="f.combo_name.errors.required">Name is required</div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </form>
                            <div class="row justify-content-center">
                                <div class="col-12">
                                    <div class="row" *ngFor="let product of purchaseProducts; let i = index;">
                                        <div class="col-3">
                                            <div class="form-group">
                                                <label>Raw Material Category<span class="text-danger">*</span></label>
                                                <ng-select name="row_material_category_{{product.id}}"
                                                    (change)="getRowMaterialProductsDropDowns($event,i)"
                                                    [(ngModel)]="product.row_material_category">
                                                    <ng-option disabled selected value="0">Select Raw Material
                                                        Category</ng-option>
                                                    <ng-option
                                                        *ngFor="let rowMaterialCategory of rowMaterialCategoryDropDown"
                                                        [value]="rowMaterialCategory.id">{{rowMaterialCategory.category_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-group">
                                                <label>Raw Material Product<span class="text-danger">*</span></label>
                                                <ng-select name="raw_material_product_{{product.id}}"
                                                    [(ngModel)]="product.raw_material_product">
                                                    <ng-option disabled selected value="0">Select Raw Material
                                                        Product</ng-option>
                                                    <ng-option
                                                        *ngFor="let rowMaterialProducts of rowMaterialProductsDropDown"
                                                        [value]="rowMaterialProducts.id">{{rowMaterialProducts.product_name}}</ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-1">
                                            <div class="form-group">
                                                <label>Qty<span class="text-danger">*</span></label>
                                                <input type="text" class="form-control" [(ngModel)]="product.quantity"
                                                    name="quantity_{{product.id}}" placeholder="Quantity" />
                                            </div>
                                        </div>

                                        <!-- <div class="col-2">
                                            <div class="form-group">
                                                <label for="register-product_photo_three" class="form-label">Product Photo</label>
                                                <input class="form-control" type="file" id="product_photo_three" [(ngModel)]="product.image" (change)="uploadFile($event,i)" name="product_photo_{{product.id}}">
                                            </div>
                                        </div> 
                                        <div class="col-2">
                                            <div class="form-group">
                                                <img [src]="product.image_src" alt="Upload" class="img-fluid card-upload-image">
                                            </div>
                                        </div> -->

                                        <div class="col-1">
                                            <div class="form-group">
                                                <br />
                                                <button type="button" class="btn btn-danger" (click)="remove(i)"
                                                    *ngIf="i != 0">
                                                    <i data-feather="trash"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12 text-end">
                                            <button type="button" class="btn btn-outline-success mb-2 float-right"
                                                (click)="addMore()"><i data-feather="plus"></i> Add More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-end">
                                    <button [disabled]="loading" class="btn btn-primary float-right"
                                        (click)="onSubmit()" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>