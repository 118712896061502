import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { OrdersService } from 'app/_services/orders.service';
import { Router } from '@angular/router';
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-top-customer',
  templateUrl: './top-customer.component.html',
  styleUrls: ['./top-customer.component.scss']
})
export class TopCustomerComponent implements OnInit {

  public topSalesman: any =[];
  public loading:boolean = true;

  constructor(private ordersService: OrdersService, private _router: Router, private _toastrService: ToastrService, private _authenticationService: AuthenticationService) { }

  ngOnInit(): void {
    this.salesmanCustomerList();
  }

  salesmanCustomerList(){
    this.ordersService.getTopCustomerList().subscribe(
      response => {
        if (response.status) {
          this.loading = false;
          this.topSalesman = response.data;
          console.log(this.topSalesman);
        } else {
          this.loading = false;
          this._toastrService.error(response.message, 'Oops!',);
        }
      },
      err => {
        this.loading = false;
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
 
}
