import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ProductMasterComboService } from 'app/_services/product-master-combo.service';
import { BeforeOpenEvent } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product-master-combo',
  templateUrl: './product-master-combo.component.html',
  styleUrls: ['./product-master-combo.component.scss']
})
export class ProductMasterComboComponent implements OnInit {

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public productsComboList: any = [];
  public comboDetails: any = [];
  public start: any = 0;
  public page: any = 0;
  public currentUser: any;
  public searchByName: any = '';

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private _toastrService: ToastrService, private http: HttpClient, private productMasterComboService: ProductMasterComboService, private modalService: NgbModal) {
    this._authenticationService.currentUser.subscribe(x => (this.currentUser = x));
  }

  ngOnInit(): void {
    this.loading = true;
    this.productComboList(this.start, this.lengths);
  }


  productComboList(my_start: any, my_lengths: any) {

    this.productMasterComboService.getData(my_start, my_lengths, this.searchByName).subscribe(
      response => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productsComboList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productComboList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productComboList(this.page, this.lengths);
  }

  loadPageLengths(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productComboList(this.start, this.lengths);
  }

  // modal Basic
  modalOpen(modalBasic, comboId: any) {
    this.comboDetails = [];
    this.modalService.open(modalBasic, {
      size: 'xl'
    });

    this.productMasterComboService.viewDetails(comboId).subscribe(
      response => {
        if (response.status) {
          this.comboDetails = response.details;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }


  delete(id: any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productMasterComboService.delete(id).subscribe(
          response => {
            if (response.status) {
              
              me.progressMainTable = true;

              
              me.reloadList();
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Product Combo Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }

  getSearchFilter(searchByName: any) {
    this.searchByName = searchByName;
    this.reloadList();
  }

  metaDelete(id: any,mainId:any) {
    const me = this;
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#7367F0',
      cancelButtonColor: '#E42728',
      confirmButtonText: 'Yes, delete it!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-danger ml-1'
      }
    }).then(function (result) {
      if (result.value) {
        me.productMasterComboService.metaDelete(id,mainId).subscribe(
          response => {
            if (response.status) {
              me.progressMainTable = true;

              me.productMasterComboService.viewDetails(mainId).subscribe(
                response => {
                  if (response.status) {
                    me.comboDetails = response.details;
                  }
                  else {
                    me.comboDetails = [];
                  }
                },
                err => {
                  if (err.error.error == 'Unauthenticated.') {
                    me._authenticationService.logout();
                    me._router.navigate(['/login']);
                  }
                }
              );
              
              me.reloadList();
              Swal.fire({
                icon: 'success',
                title: 'Deleted!',
                text: 'Raw Material Deleted Successfully',
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Oops!',
                text: response.message,
                customClass: {
                  confirmButton: 'btn btn-success'
                }
              });
            }
          },
          err => {
            if (err.error.error == 'Unauthenticated.') {
              this._authenticationService.logout();
              this._router.navigate(['/login']);
            }
          }
        );
      }
    });
  }
}
