import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'app/auth/service';
import { Router } from '@angular/router';
import { ProductsService } from 'app/_services/products.service';

@Component({
  selector: 'app-product-stock-maintain-add',
  templateUrl: './product-stock-maintain-add.component.html',
  styleUrls: ['./product-stock-maintain-add.component.scss']
})
export class ProductStockMaintainAddComponent implements OnInit {

  public loading = false;
  public loadingLoad = false;
  public lengths = 30;
  public searchByName: any = '';
  public allProductList: any = [];
  public newForm: FormGroup;
  public submitted = false;
  public loadingForm = false;
  public error = '';
  public page = 1;
  public allItemsLoaded = false;
  public categoryDropDown: any = [];
  public categoryFilter: any = [];

  constructor(private _router: Router, private _authenticationService: AuthenticationService, private productsService: ProductsService, private _toastrService: ToastrService,  private _formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loading = true;
    this.productDataList();
    this.getCategoryDropDown();
    this.newForm = this._formBuilder.group({
      items: this._formBuilder.array([])
    });
  }

  get items(): FormArray {
    return this.newForm.get('items') as FormArray;
  }

  productDataList() {
    this.loadingLoad = true;
    this.productsService.getData(this.page, this.lengths, this.categoryFilter, this.searchByName).subscribe(
      response => {
        if (response.status) {
          if (response.data.length < this.lengths) {
            this.allItemsLoaded = true;  // No more items to load
          }
          // Correctly append new data to the existing array
          this.allProductList = response.data;
          this.page += 1;  // Move to the next page
          this.loadingLoad = false;
          this.loading = false;
          response.data.forEach((value: any) => {
            let product_thum = '';
            if(value.product_image?.length != 0){
              product_thum = value.product_image[0].product_thum;
            }else{
              product_thum = 'assets/images/no-img.png';
            }
            const itemGroup = this._formBuilder.group({
              id: [value.id],
              product_name: [value.product_name],
              product_thum: product_thum,
              category_name: [value.category_name],
              product_code: [value.product_code],
              sales_price: [value.sales_price],
              purchase_price: [value.purchase_price],
              size_title: [value.size_title],
              color_title: [value.color_title],
              unit: [value.unit],
              current_stock: [value.current_stock],
              real_stock: ['', Validators.min(0)],
              difference: [{ value: '', disabled: true }],
            });
            // Calculate the difference when real_stock changes
            itemGroup.get('real_stock')?.valueChanges.subscribe(real_stock => {
              const difference = value.current_stock - real_stock;
              itemGroup.get('difference')?.setValue(difference);
            });
            this.items.push(itemGroup);
          });
        }
      },err => {
        if (err.error.error === 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  onSubmit(){
    this.submitted = true;
    if (this.newForm.valid) {
      this.loadingForm = true;
      const formData = new FormData();
      this.items.controls.forEach((item, index) => {
        if(item.get('real_stock')?.value != ''){
          formData.append('id[]', item.get('id')?.value);
          formData.append('current_stock[]', item.get('current_stock')?.value);
          formData.append('real_stock[]', item.get('real_stock')?.value);
        }
      });
      this.productsService.addMaintain(formData).subscribe(
        data => {
          this.loadingForm = false;
          if (data.status) {
            this.submitted = false;
            this.items.clear();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.items.clear(); 
            this.productDataList();
          }else {
            this.error = data.error;
          }
        },err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }else {
      return false;
    }
  }

  getCategoryDropDown() {
    this.productsService.getCategoriesDropDown().subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.categories;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      });
  }

  getCategoryFilter(categories:any) {
    this.categoryFilter = categories;
    this.page = 0;
    this.items.clear(); 
    this.productDataList();
  }

  getRawMaterialFilter(searchByName:any) {
    this.searchByName = searchByName;
    this.page = 0;
    this.items.clear(); 
    this.productDataList();
  }

}
