import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType: 'blob' });

@Injectable({
  providedIn: 'root'
})
export class ProductMasterComboService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length, search_name: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-auto`, { start, length, search_name })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(products_id: any, combo_name: any, comboProducts: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}save-product-combo`, { products_id, combo_name, comboProducts })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  viewDetails(comboId,type:any = 0): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-auto-details`, { comboId,type })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  downloadReport(data): Observable<Blob> {
    return this._http.post<Blob>(`${environment.apiUrl}product-combo-report`, data, {
      headers: headers, responseType:
        'blob' as 'json'
    });
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-delete`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  metaDelete(id,mainId): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product-combo-raw-material-delete`, { id,mainId })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
