<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Employee</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Employee</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()" autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-name" class="form-label">Name<span
                                                class="text-danger">*</span></label>
                                        <input type="text" formControlName="name" class="form-control"
                                            placeholder="Name" aria-describedby="register-name" tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                            <div *ngIf="f.name.errors.required">Name is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-phone" class="form-label">Phone Number<span
                                                class="text-danger">*</span></label>
                                        <input type="text" formControlName="phone" class="form-control"
                                            placeholder="Phone Number" minlength=10 maxlength=10
                                            aria-describedby="register-phone" tabindex="3"
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                                            <div *ngIf="f.phone.errors.pattern">Please Provide Valid Mobile No.</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-4 ">
                                        <label for="register-email" class="form-label">Email</label>
                                        <input type="text" formControlName="email" class="form-control"
                                            placeholder="Email" aria-describedby="register-email" tabindex="5"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.email">Please Enter Valid Email</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-12 col-sm-6 col-md-4 col-lg-12 ">
                                        <label for="address" class="form-label">Address<span
                                                class="text-danger">*</span></label>
                                        <textarea formControlName="address" class="form-control" placeholder="Address"
                                            aria-describedby="register-address_one" tabindex="6"
                                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                            <div *ngIf="f.address.errors.required">Address is required</div>
                                        </div>
                                    </div>


                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="register-product_photo_one"
                                                            class="form-label">Document 1</label>
                                                        <input class="form-control" type="file" id="product_photo_one"
                                                            (change)="uploadFile($event,1)" name="product_photo_one"
                                                            formControlName="product_photo_one" tabindex="16"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_one.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="register-product_photo_two"
                                                            class="form-label">Document 2</label>
                                                        <input class="form-control" type="file" id="product_photo_two"
                                                            (change)="uploadFile($event,2)" name="product_photo_two"
                                                            formControlName="product_photo_two" tabindex="17"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_two.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="register-product_photo_three"
                                                            class="form-label">Document 3 </label>
                                                        <input class="form-control" type="file" id="product_photo_three"
                                                            (change)="uploadFile($event,3)" name="product_photo_three"
                                                            formControlName="product_photo_three" tabindex="18"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_three.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-3">
                                        <div class="card card-upload">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <label for="register-product_photo_four"
                                                            class="form-label">Document 4</label>
                                                        <input class="form-control" type="file" id="product_photo_four"
                                                            (change)="uploadFile($event,4)" name="product_photo_four"
                                                            formControlName="product_photo_four" tabindex="19"
                                                            [ngClass]="{ 'is-invalid': submitted && f.product_photo_four.errors }">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-sm-12 col-xs-12 col-lg-8">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/employees"
                                            class="btn btn-secondary btn-block" tabindex="17" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode"
                                            tabindex="15" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block"
                                            *ngIf="!isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>