import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FactoryEmployeeService {

  constructor(private _http: HttpClient) { }

  getData(start, length, is_set_price): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-factory-employee`, { start, length, is_set_price })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add_old(name: any, mobile: any, email: any, address: any, photo_one: any, photo_two: any, photo_three: any, photo_four: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-factory-employee`, { name, mobile, email, address, photo_one, photo_two, photo_three, photo_four })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-factory-employee`, { name, mobile, email, address, photo_one, photo_two, photo_three, photo_four, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  add(productData: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-factory-employee`, productData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-factory-employee`, productData)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }



  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-factory-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}edit-factory-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }


  dealerViewDetails(id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}show-details-employee`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
