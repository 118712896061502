<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Gate Pass</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Gate Pass</h4>
                        </div>
                        <div class="card-body pb-1">

                            <div class="col col-sm-12 col-xs-12 col-lg-8">
                                <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                    <div class="alert-body">
                                        <p>{{ error }}</p>
                                    </div>
                                </ngb-alert>
                            </div>
                            <form *ngIf="!loading" name="form" [formGroup]="newForm" (ngSubmit)="onSubmit()"
                                autocomplete="off">
                                <div class="row">
                                    <div class="form-group col-4">
                                        <label for="vendor_id" class="form-label">Select Vendor<span
                                                class="text-danger">*</span></label>
                                        <ng-select [items]="vendorDropDown" id="vendor_id" [clearable]="false"
                                            bindValue="id" bindLabel="name" formControlName="vendor_id"
                                            aria-describedby="vendor_id" placeholder="Select Vendor"
                                            [ngClass]="{ 'is-invalid': submitted && f.vendor_id.errors }"> </ng-select>
                                        <div *ngIf="submitted && f.vendor_id.errors" class="invalid-feedback">
                                            <div *ngIf="f.vendor_id.errors.required">Vendor is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-2 ">
                                        <label for="is_product" class="form-label">Is Product<span
                                                class="text-danger">*</span></label>
                                        <select #isproduct formControlName="is_product" class="form-control"
                                            aria-describedby="is_product" tabindex="14"
                                            [ngClass]="{ 'is-invalid': submitted && f.is_product.errors }"
                                            (change)="onChangeProduct(isproduct.value)">
                                            <option value="" selected>Select Is Product</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div *ngIf="submitted && f.is_product.errors" class="invalid-feedback">
                                            <div *ngIf="f.is_product.errors.required">Is Product is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-2 ">
                                        <label for="is_returnable" class="form-label">Is Returnable<span
                                                class="text-danger">*</span></label>
                                        <select #is_returnable_select formControlName="is_returnable"
                                            class="form-control" aria-describedby="is_returnable" tabindex="14"
                                            [ngClass]="{ 'is-invalid': submitted && f.is_returnable.errors }"
                                            (change)="onChangeReturnable(is_returnable_select.value)">
                                            <option value="" selected>Select Is Returnable</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                        <div *ngIf="submitted && f.is_returnable.errors" class="invalid-feedback">
                                            <div *ngIf="f.is_returnable.errors.required">Is Returnable is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-4 ">
                                        <label for="transport" class="form-label">Transport</label>
                                        <input type="text" formControlName="transport" class="form-control"
                                            placeholder="Transport" aria-describedby="transport" tabindex="1"
                                            [ngClass]="{ 'is-invalid': submitted && f.transport.errors }" />
                                        <div *ngIf="submitted && f.transport.errors" class="invalid-feedback">
                                            <div *ngIf="f.transport.errors.required">Transport is required</div>
                                        </div>
                                    </div>

                                    <div class="col-6">
                                        <label>Remarks</label>
                                        <textarea formControlName="remarks" class="form-control" rows="4" id="remarks"
                                            placeholder="Remark If Any"
                                            [ngClass]="{ 'is-invalid': submitted && f.remarks.errors }"></textarea>
                                        <div *ngIf="submitted && f.remarks.errors" class="invalid-feedback">
                                            <div *ngIf="f.remarks.errors.required">Remarks is required</div>
                                        </div>
                                    </div>
                                    <div class="col-6">

                                        <div class="form-group">
                                            <label class="form-label">Status</label>
                                            <ng-select  formControlName="status" aria-describedby="status" class="" [ngClass]="{ 'is-invalid': submitted && f.status.errors }">
                                                <ng-option [value]="0">Pending</ng-option>
                                                <ng-option [value]="1">Half Received</ng-option>
                                                <ng-option [value]="2">Completed</ng-option>
                                                <ng-option [value]="3">Cancel</ng-option>
                                                <ng-option [value]="4">Manualy Closed</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.status.errors" class="invalid-feedback">
                                                <div *ngIf="f.status.errors.required">Status is required</div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="pt-3 col-12 form-group" formArrayName="products_data">
                                        <div class="row border-bottom mb-1"
                                            *ngFor="let product of productsData.controls; let i = index"
                                            [formGroupName]="i">


                                            <div *ngIf="f.is_product.value == 0" class="form-group col-4 ">
                                                <label for="product_details" class="form-label">Product Details<span
                                                        class="text-danger">*</span></label>
                                                <input type="text" formControlName="product_details"
                                                    class="form-control" placeholder="Product Details"
                                                    aria-describedby="product_details" tabindex="1"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('product_details').errors }" />
                                                <div *ngIf="submitted && product.get('product_details').errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="product.get('product_details').errors?.required">
                                                        Product Details is required</div>
                                                </div>
                                            </div>

                                            <div *ngIf="f.is_product.value == 1" class="form-group col-4 ">
                                                <label for="product_id" class="form-label">Select Product<span
                                                        class="text-danger">*</span></label>
                                                <!-- <input type="text" formControlName="product_id" class="form-control"
                                                    placeholder="Product" aria-describedby="product_id" tabindex="1"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('product_id').errors }" /> -->


                                                <ng-autocomplete
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('product_id').errors }"
                                                    formControlName="product_id" #auto [data]="allProduct"
                                                    [valueField]="'id'" name="products" id="products" [initialValue]=""
                                                    [minQueryLength]="3" placeholder="Search Product, Min. 3 Characters"
                                                    [searchKeyword]="keywordProduct" [historyListMaxNumber]="0"
                                                    notFoundText="Not found"
                                                    (inputChanged)='onChangeSearchProduct($event)'
                                                    [itemTemplate]="itemTemplateStaticProduct"
                                                    [isLoading]="isLoadingProduct"
                                                    [notFoundTemplate]="notFoundTemplateProduct"
                                                    [ngModel]="selectedProductsValue[i]">
                                                </ng-autocomplete>
                                                <ng-template #itemTemplateStaticProduct let-items>
                                                    <a [innerHTML]="items.full_name"></a>
                                                </ng-template>
                                                <ng-template #notFoundTemplateProduct let-notFound>
                                                    <div [innerHTML]="notFound"></div>
                                                </ng-template>
                                                <div *ngIf="submitted && product.get('product_id').errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="product.get('product_id').errors?.required">Product
                                                        is required</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-2">
                                                <label for="product_qty" class="form-label">Qty</label>
                                                <input type="text" formControlName="product_qty" class="form-control"
                                                    placeholder="product_qty" aria-describedby="product_qty"
                                                    tabindex="1"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('product_qty').errors }" />
                                                <div *ngIf="submitted && product.get('product_qty').errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="product.get('product_qty').errors?.required">Product Qty
                                                        is required</div>
                                                </div>
                                            </div>

                                            <div *ngIf="newForm.get('is_returnable').value == '1'"
                                                class="form-group col-3">
                                                <label for="expected_arrival_date" class="form-label">Expected
                                                    Arrival Date<span class="text-danger">*</span></label>
                                                <!-- <input type="text" formControlName="expected_arrival_date"
                                                    class="form-control" placeholder="Expected Arrival Date"
                                                    aria-describedby="expected_arrival_date" tabindex="1"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('expected_arrival_date').errors }" /> -->

                                                <input type="text" readonly formControlName="expected_arrival_date"
                                                    id="expected_arrival_date_{{i}}" placeholder="dd-mm-yyyy"
                                                    class="form-control" (click)="basicDP.toggle()" ngbDatepicker
                                                    #basicDP="ngbDatepicker" [minDate]="minDate"
                                                    aria-describedby="expected_arrival_date" tabindex="3"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('expected_arrival_date').errors }"
                                                    [ngModel]="selectedDates[i]" />

                                                <div *ngIf="submitted && product.get('expected_arrival_date').errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="product.get('expected_arrival_date').errors?.required">
                                                        Expected Arrival Date is required</div>
                                                </div>
                                            </div>

                                            <div class="form-group col-2"
                                                *ngIf="newForm.get('is_returnable').value == '1' && !isAddMode">
                                                <label for="status" class="form-label">Status<span
                                                        class="text-danger">*</span></label>
                                                <select formControlName="status" class="form-control"
                                                    aria-describedby="status" tabindex="14"
                                                    [ngClass]="{ 'is-invalid': submitted && product.get('status').errors }">
                                                    <option value="" selected>Select Status</option>
                                                    <option value="1">Open</option>
                                                    <option value="0">Close</option>
                                                </select>
                                                <div *ngIf="submitted && product.get('status').errors"
                                                    class="invalid-feedback">
                                                    <div *ngIf="product.get('status').errors.required">Status is
                                                        required</div>
                                                </div>
                                            </div>

                                            <div class="col-11">
                                                <label>Comments</label>
                                                <textarea formControlName="comments" class="form-control" rows="2"
                                                    id="comments" placeholder="Comments If Any"></textarea>
                                            </div>

                                            <div class="col-1">
                                                <div class="form-group">
                                                    <br />
                                                    <button type="button" class="btn btn-danger"
                                                        (click)="removeProduct(product,i)" *ngIf="i != 0">
                                                        <i data-feather="trash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row pt-1">
                                            <div class="col-12 text-end">
                                                <button type="button" class="btn btn-outline-success mb-2 float-right"
                                                    (click)="addProduct()"><i data-feather="plus"></i> Add More</button>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" type="button" routerLink="/gate-pass"
                                            class="btn btn-secondary btn-block" tabindex="17" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                        </button>
                                    </div>
                                    <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                        <button [disabled]="loading" class="btn btn-primary btn-block" *ngIf="isAddMode"
                                            tabindex="15" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Save
                                        </button>
                                        <button [disabled]="loading" class="btn btn-primary btn-block"
                                            *ngIf="!isAddMode" tabindex="16" rippleEffect>
                                            <span *ngIf="loading"
                                                class="spinner-border spinner-border-sm mr-1"></span>Update
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>