<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="collapsible">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Top Salesman & Customer List</h4>
                        </div>
                        <div class="row custom-collapsible">
                            <div class="col-12 col-sm-12 col-md-12 col-lg12">
                                <div class="card-body collapse-icon">
                                    <div class="card-body p-0 pb-1" *ngIf="loading">
                                        <div class="text-center">
                                            <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                            </div>
                                            <div>
                                                Please Wait...
                                            </div>
                                        </div>
                                    </div>
                                    <ngb-accordion [closeOthers]="true" activeIds="accordion-1" *ngIf="!loading">
                                        <ngb-panel id="accordion-{{ndx+1}}" *ngFor="let ts of topSalesman; let ndx = index">
                                            <ng-template ngbPanelTitle>
                                                <div class="lead collapse-title"> 
                                                    <div class="d-flex align-items-center justify-content-between pr-2">
                                                        <div>
                                                            {{ts.employee_name}}
                                                        </div>
                                                        <div>
                                                            <span class="badge badge-light-primary mr-1">Orders: {{ts.total_order}} </span> 
                                                            <span class="badge badge-light-dark">Amounts: {{ts.total_amount}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                            <ng-template ngbPanelContent>
                                               <h5 class="text-danger">Top 10 Customers</h5>
                                               <table class="table table-striped table-hover valign-middle text-dark w-100">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Customer</th>
                                                            <th>Order</th>
                                                            <th>Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr  *ngFor="let tc of ts.customer; let tcx = index">
                                                            <td>{{tcx+1}}</td>
                                                            <td>{{tc.customer_name}}</td>
                                                            <td>{{tc.total_order}}</td>
                                                            <td>{{tc.total_amount}}</td>
                                                        </tr>
                                                    </tbody>
                                               </table>
                                            </ng-template>
                                        </ngb-panel>
                                    </ngb-accordion>
                                    <div *ngIf="topSalesman?.length == 0 && !loading">
                                        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                                            <img class="img-fluid opacity-50" [src]="'assets/images/pages/no-data.svg'" style="width:35%" alt="No Data Found!" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
